<template>
  <ul class="menu-nav">
    <div class="w-100 mb-10 vertical-center d-flex justify-content-between" v-if="isMobile">
      <img class="m-5" :src="logoDark" :height="25" />
      <v-btn class="m-5" icon small @click="closeSideMenu()">
        <i class="flaticon-cancel font-bold font-20 color-pink"></i>
      </v-btn>
    </div>
    <router-link
      to="/live_events"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span :class="isMobile ? 'font-12 font-bold' : 'menu-text'"> EVENTS LIVE </span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/become_an_organizer"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span :class="isMobile ? 'font-12 font-bold' : 'menu-text'"> {{ $t('become_an_organizer').toUpperCase() }} </span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/contact_us"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span :class="isMobile ? 'font-12 font-bold' : 'menu-text'"> {{ $t('contact_us').toUpperCase() }} </span>
        </a>
      </li>
    </router-link>

    <span class="ml-5 mr-5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

    <router-link
      to="/login"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="!myInfo && !isMobile"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span :class="isMobile ? 'font-12 font-bold' : 'menu-text'">{{ $t('login').toUpperCase() }} </span>
        </a>
      </li>
    </router-link>

    <div class="mt-40 w-100 center" v-if="!myInfo && isMobile">
      <v-btn class="mt-40 w-75 button-empty" @click="$router.push(`/login`)">
        <span class="px-5">{{ $t('login') }}</span>
      </v-btn>
      <v-btn class="mt-5 w-75 button-normal" @click="$router.push(`/select_user_type`)">
        <span class="px-5">{{ $t('register') }}</span>
      </v-btn>
    </div>

  </ul>
</template>

<script>
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";

import logoDark from '@/assets/logo/logo_dark.png';

export default {
  name: 'KTMenu',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    myInfo() {
      return this.$store.state.myInfo;
    }
  },
  data() {
    return {
      logoDark
    }
  },
  methods: {
    closeSideMenu() {
      KTLayoutHeaderMenu.closeMobileOffcanvas();
    }
  }
};
</script>
