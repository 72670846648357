var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[(_vm.isMobile)?_c('div',{staticClass:"w-100 mb-10 vertical-center d-flex justify-content-between"},[_c('img',{staticClass:"m-5",attrs:{"src":_vm.logoDark,"height":25}}),_c('v-btn',{staticClass:"m-5",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.closeSideMenu()}}},[_c('i',{staticClass:"flaticon-cancel font-bold font-20 color-pink"})])],1):_vm._e(),_c('router-link',{attrs:{"to":"/live_events"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{class:_vm.isMobile ? 'font-12 font-bold' : 'menu-text'},[_vm._v(" EVENTS LIVE ")])])])]}}])}),_c('router-link',{attrs:{"to":"/become_an_organizer"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{class:_vm.isMobile ? 'font-12 font-bold' : 'menu-text'},[_vm._v(" "+_vm._s(_vm.$t('become_an_organizer').toUpperCase())+" ")])])])]}}])}),_c('router-link',{attrs:{"to":"/contact_us"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{class:_vm.isMobile ? 'font-12 font-bold' : 'menu-text'},[_vm._v(" "+_vm._s(_vm.$t('contact_us').toUpperCase())+" ")])])])]}}])}),_c('span',{staticClass:"ml-5 mr-5"},[_vm._v("     ")]),(!_vm.myInfo && !_vm.isMobile)?_c('router-link',{attrs:{"to":"/login"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{class:_vm.isMobile ? 'font-12 font-bold' : 'menu-text'},[_vm._v(_vm._s(_vm.$t('login').toUpperCase())+" ")])])])]}}],null,false,275903133)}):_vm._e(),(!_vm.myInfo && _vm.isMobile)?_c('div',{staticClass:"mt-40 w-100 center"},[_c('v-btn',{staticClass:"mt-40 w-75 button-empty",on:{"click":function($event){return _vm.$router.push(`/login`)}}},[_c('span',{staticClass:"px-5"},[_vm._v(_vm._s(_vm.$t('login')))])]),_c('v-btn',{staticClass:"mt-5 w-75 button-normal",on:{"click":function($event){return _vm.$router.push(`/select_user_type`)}}},[_c('span',{staticClass:"px-5"},[_vm._v(_vm._s(_vm.$t('register')))])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }